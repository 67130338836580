import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import productActions from '../../Redux/Products/action';
import Slider from "react-slider";
import { useTranslation } from 'react-i18next';
import { LogEvent } from "../../utils/firebase";
import { MdOutlineCurrencyRupee } from "react-icons/md";

export default function SidebarFilters(props) {
    const { category, handleCloseModal } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { productFilterList, selectedProductFilter, productfilter } = useSelector(state => state.ProductListReducer);
    const { currentLanguage } = useSelector(state => state.commonReducer);
    const [priceRange, setPriceRange] = useState([0, 1]);

    useEffect(() => {
        if (productFilterList?.PriceFilter) {
            setPriceRange([productFilterList.PriceFilter.Min, productFilterList.PriceFilter.Max]);
        }
    }, [productFilterList]);

    const logAndDispatchFilter = (updatedFilter, eventType) => {
        const startTime = Date.now();
        dispatch({ type: productActions.SELECTED_PRODUCT_FILTER, payload: updatedFilter });
        dispatch({
            type: productActions.PRODUCT_FILTER,
            payload: updatedFilter,
            callback: () => {
                const endTime = Date.now();
                const timeTaken = endTime - startTime;
                LogEvent(`${eventType}_Filter_Time`, { timeTaken });
            }
        });
    };

    const handleSliderChange = (event) => {
        LogEvent('Products_Price_Filter_Clicked');
        setPriceRange(event);
        const updatedFilter = { ...selectedProductFilter, Price: event };
        logAndDispatchFilter(updatedFilter, 'Price');
    };

    // useEffect(() => {
    //     dispatch({ type: productActions.GET_PRODUCT_FILTER });
    // }, [dispatch]);

    const handleChangeCategory = (e, value) => {
        LogEvent('Products_Category_Filter_Clicked');
        let categoryArr = [...selectedProductFilter?.Category];
        if (e.target.checked) {
            categoryArr.push(value);
        } else {
            categoryArr = categoryArr.filter(val => val !== value);
        }
        const updatedFilter = { ...selectedProductFilter, Category: categoryArr };
        logAndDispatchFilter(updatedFilter, 'Category');
    };

    const handleResetFilter = (e) => {
        e.preventDefault();
        if (productFilterList?.PriceFilter) {
            setPriceRange([productFilterList.PriceFilter.Min, productFilterList.PriceFilter.Max]);
        }
        const updatedFilter = { Category: [], Price: null };
        logAndDispatchFilter(updatedFilter, 'Reset');
    };

    return (
        <div>
            <div className="products_lits_page_sidebar_filter_main_div">
                <div className="d-flex justify-content-between mb-3">
                    <h5 className="products_list_page_filter_by_text">{t("Filter by")}</h5>
                    <div className="products_list_page_clear_all_btn_div" onClick={handleResetFilter}>{t("Reset")}</div>
                </div>
                {category !== 'market' && productFilterList?.Category?.length > 0 && (
                    <div className="product_list_view_cate_filter_page_main_div">
                        <div className="market-animals-cate-filter-header">{t("Categories")}</div>
                        <div className="products_list_cate_filter_category_before_map">
                            {productFilterList?.Category?.map((val, i) => (
                            // {productfilter?.data?.map((val, i) => (
                                <div className="products_list_cate_filter_category cursor-pointer" key={i}>
                                    <div className="market-animals-cate-filter-div">
                                        <input
                                            className="form-check-input products_list_category_checkbox"
                                            type="checkbox"
                                            id={`flexCheckDefault${i}`}
                                            onChange={(e) => handleChangeCategory(e, val?.cattle_type)}
                                            checked={selectedProductFilter?.Category?.includes(val?.cattle_type)}
                                            // onChange={(e) => handleChangeCategory(e, val?.category)}
                                            // checked={selectedProductFilter?.Category?.includes(val?.category)}
                                        />
                                        <label
                                            className={`market-animals-cate-filter-title ps-2 ${selectedProductFilter?.Category?.includes(val?.cattle_type) && 'market-animals-cate-filter-title-active'}`}
                                            // className={`market-animals-cate-filter-title ps-2 ${selectedProductFilter?.Category?.includes(val?.category) && 'market-animals-cate-filter-title-active'}`}
                                            htmlFor={`flexCheckDefault${i}`}
                                        >
                                            {val?.language?.find(lang => lang?.language_code === currentLanguage)?.category || val?.cattle_type}
                                            {/* {val?.language?.find(lang => lang?.language_code === currentLanguage)?.category || val?.category} */}
                                        </label>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                <h5 className="products_list_page_filter_by_text d-flex my-4">
                    {t("Price Filter")}
                    <div className="products_list_page_filter_by_text_sub_div">
                        (₹)
                    </div>
                </h5>
                <Slider
                    className="slider mt-3"
                    value={priceRange}
                    onChange={handleSliderChange}
                    min={productFilterList?.PriceFilter?.Min}
                    max={productFilterList?.PriceFilter?.Max}
                />
                <div className="d-flex justify-content-between mt-4">
                    <span className="range-minandmax">₹{priceRange[0]}</span>
                    <span className="range-minandmax">₹{priceRange[1]}</span>
                </div>
            </div>
            <div className="text-center mt-3 product-filter-submi-btn">
                <Button onClick={handleCloseModal}>{t("Submit")}</Button>
            </div>
        </div>
    );
}
