import React, { useState, useEffect } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import market_Action from "../../Redux/marketPlace/action";
import commonActions from "../../Redux/common/actions";
import userActions from "../../Redux/user/actions";
import { FiFilter } from "react-icons/fi";
import { MdKeyboardArrowDown } from 'react-icons/md';
import { FaLocationDot } from "react-icons/fa6";
// import InfiniteScroll from 'react-infinite-scroll-component';
import { DecryptFunction } from "../../utils/cryptoFunction";
import SidebarFilter from "./sidebarFilter";
import List from "./list";
import Pagination from "../../common/pagination";

export default function MarketPlaceOtherServicesIndex() {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { marketPlaceSelectedLocation, serviceproviderlist } = useSelector((state) => state.MarketPlaceReducer);

    const [showFilterModal, setShowFilterModal] = useState(false);
    // const [fetchedData, setFetchedData] = useState([]);
    const [itemOffset, setItemOffset] = useState(0);
    const [itemsPerPage] = useState(6);
    const [currentPage, setCurrentPage] = useState(0);
    const { currentLanguage } = useSelector(state => state.commonReducer);

    const handleCloseModal = () => {
        setShowFilterModal(false);
    };

    useEffect(() => {
        dispatch({ type: market_Action.GET_SERVICE_PROVIDER_BY_CATEGORY });
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (localStorage.getItem("token") && localStorage.getItem('auth_user')) {
            const decryptedId = DecryptFunction(localStorage.getItem('auth_user'));
            if (decryptedId) {
                dispatch({ type: userActions.GET_USER_ADDRESS, payload: { id: decryptedId } });
            }
        };
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    dispatch({ type: commonActions.GET_CURRENT_LOCATION, payload: { latitude: latitude, longitude: longitude } })
                },
                (error) => {
                    if (error.code === 1) {
                    } else {
                        console.error('Error getting location:', error.message);
                    }
                }
            );
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        callBackfunction()
        //eslint-disable-next-line
    }, [marketPlaceSelectedLocation?.Category, marketPlaceSelectedLocation?.Address]);

    const callBackfunction = () => {
        if (marketPlaceSelectedLocation?.Category && marketPlaceSelectedLocation?.Address) {
            dispatch({
                type: market_Action.GET_LIST_OF_SERVICE_PROVIDER_WITH_THEIR_ID,
            });
        }
    };

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = serviceproviderlist?.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(serviceproviderlist?.length / itemsPerPage);

    const handlePageChange = (event) => {
        const newOffset = (event.selected * itemsPerPage) % serviceproviderlist?.length;
        setItemOffset(newOffset);
        setCurrentPage(event?.selected);
        window.scrollTo({ top: 0 })
    };

    // useEffect(() => {
    //     if (serviceproviderlist?.length > 0) {
    //         setFetchedData(serviceproviderlist?.slice(0, 10));
    //     };
    // }, [serviceproviderlist]);

    // const fetchMoreData = () => {
    //     if (serviceproviderlist?.length === fetchedData?.length) {
    //         return;
    //     } else {
    //         let currentLength = fetchedData?.length;
    //         setFetchedData([...fetchedData, ...serviceproviderlist?.slice(currentLength, currentLength + 10)])
    //     }
    // };

    return (
        <Container className="my-5">
            <Row>
                {/* <Col md={12} className="mb-4">
                  
                </Col> */}
                <Col lg={4} md={12} sm={12}>
                    <div className="big-screen-filter-sidebar">
                        <SidebarFilter />
                    </div>
                </Col>
                <Col lg={8} md={12} sm={12}>
                    {/* <InfiniteScroll
                        dataLength={fetchedData?.length}
                        next={fetchMoreData}
                        hasMore={true}
                        style={{ overflow: "hidden", marginBottom: "3rem" }}
                    > */}
                    <Row className="row-padding-remover">
                        <Col md={2} xs={2} className="d-flex justify-content-end">
                            <div className="small-screen-filter-modal" onClick={() => setShowFilterModal(true)}>
                                <FiFilter size={20} />
                            </div>
                        </Col>
                        {marketPlaceSelectedLocation?.Address ?
                            <Col md={12} xs={12} className="service-provider-location-col">
                                  {/* <Col md={12} xs={12} className="mb-4 mt-2 service-provider-location-col"></Col> */}
                                  <div className="page_heading_div_cls">
                        {t("Service Providers")}
                    </div>
                    {/* {doctor?.language?.find(lang => lang?.language_code === currentLanguage)?.title || doctor.title}                                */}
                                <h6 className="service-rovider-location"><span className="text-secondary">{t("Your Location")} :</span> <FaLocationDot className="service-location-icon" /> {marketPlaceSelectedLocation?.Address} <MdKeyboardArrowDown /></h6>
                                {/* <h6 className="service-rovider-location"><span className="text-secondary">{t("Your Location")} :</span> <FaLocationDot className="service-location-icon" /> {marketPlaceSelectedLocation?.find(lang => lang?.language_code === currentLanguage)?.Address || marketPlaceSelectedLocation.Address} <MdKeyboardArrowDown /></h6> */}
                            </Col> :
                            <Col md={12} xs={12} className="mb-4 mt-2 service-provider-location-col">
                                <h6 className="service-rovider-location text-danger"><span className="text-secondary">{t("Your Location")} : </span>{t("Location Not Found. Allow your location to view services")}</h6>
                            </Col>
                        }
                    </Row>
                    <List fetchedData={currentItems} />
                    {pageCount > 1 &&
                        <div className="mt-4">
                            <Pagination pageCount={pageCount} onPageChange={handlePageChange} currentPage={currentPage} />
                        </div>
                    }
                    {/* </InfiniteScroll> */}
                </Col>
            </Row>
            <Modal
                dialogClassName="product-filter-modal"
                show={showFilterModal}
                onHide={handleCloseModal}
                centered
            >
                <div className="modal_close_icons" onClick={handleCloseModal}>
                    <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 24 24"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                    </svg>
                </div>
                <Container className="small-screen-filter-cantainer">
                    <SidebarFilter handleCloseModal={handleCloseModal} />
                </Container>
            </Modal>
        </Container>
    );
}
