import commonActions from "./actions";
import checkoutActions from "../checkout/actions";
import marketActions from '../marketPlace/action';
import { takeEvery, call, all, put, select } from "redux-saga/effects";
import axios from "axios";
import { API_URL, GOOGLE_API, GOOGLE_MAPS_API_KEY } from "../../utils/constant";
import setAuthToken from "../../utils/setAuthToken";

const commonSagas = function* () {
    yield all([
        yield takeEvery(commonActions.GET_WEBSITE_LANGUAGES, getWebsiteLanguages),
        yield takeEvery(commonActions.GET_COMMON_TOKEN, getCommonToken),
        yield takeEvery(commonActions.GET_STATES, getStates),
        yield takeEvery(commonActions.GET_ADDRESS_COORDINATES, getAddressCoordinates),
        yield takeEvery(commonActions.GET_CURRENT_LOCATION, getCurrentLocation),
    ])
};

const getWebsiteLanguages = function* () {
    try {
        const { websiteLanguages } = yield select(state => state.commonReducer);

        // if no data in websiteLanguages, update the current language state with the language in session storage
        if (websiteLanguages?.length === 0) {
            yield put({ type: commonActions.CURRENT_LANGUAGE, payload: sessionStorage.getItem('YFLanguage') })
            const result = yield call(() =>
                axios.get(`${API_URL}/v1/languages`)
            );

            //if the api call is succesfull then update the website languages state with the data
            if (result?.data?.code === 200) {
                yield put({ type: commonActions.SET_WEBSITE_LANGUAGES, payload: result?.data?.data });
            };
        }
    } catch (err) {
        console.log(err);
    };
};

const getCommonToken = function* () {
    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/v1/login/app`, {
                "access_key": "qCX1JP1BryOVHHmSgnqyeeSYphPNbmg-TsZ2LJPgoXo"
            })
        );
        if (result?.data?.code === 200) {
            var token = result?.headers?.authorization;
            sessionStorage.setItem("temp_Token", token.replace("Bearer ", ""));
            setAuthToken(token);
        };
        window.location.reload();
    } catch (err) {
        console.log(err);
    };
};

const getStates = function* () {
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/v1/states`)
        );
        if (result?.data?.code === 200) {
            yield put({ type: commonActions.SET_STATES, payload: result?.data?.data });
        };
    } catch (err) {
        console.log(err);
    };
};

const getAddressCoordinates = function* (data) {
    const { payload } = data;
    try {
        const Address = `${payload?.address_line1}, ${payload?.address_line2}, ${payload?.address_line3}, ${payload?.city} - ${payload?.pincode}, ${payload?.state}, ${payload?.country}`;
        const result = yield call(() =>
            fetch(
                `${GOOGLE_API}/maps/api/geocode/json?address=${Address}&key=${GOOGLE_MAPS_API_KEY}`
            ).then(response => response.json())
        );
        if (result?.results?.length > 0) {
            const { lat, lng } = result?.results[0]?.geometry?.location;
            // const { sellerProfileAddress } = yield select((state) => state.commonReducer);
            const { checkoutValues } = yield select((state) => state.checkoutReducer);
            const { marketPlaceSelectedLocation } = yield select((state) => state.MarketPlaceReducer);
            yield put({ type: checkoutActions.CHECKOUT_VALUES, payload: { ...checkoutValues, Coordinates: { latitude: lat, longitude: lng } } });
            yield put({
                type: marketActions.MARKETPLACE_SERVICES_SELECTED_LOCATION,
                payload: {
                    ...marketPlaceSelectedLocation,
                    Address: Address,
                    Coordinates: { latitude: lat, longitude: lng },
                    type: 'User'
                }
            });
            yield put({
                type: commonActions.SET_SELLER_PROFILE_SERVICES_SELECTED_LOCATION,
                payload: {
                    // ...sellerProfileAddress,
                    Coordinates: { latitude: lat, longitude: lng },
                }
            });
            yield put({
                type: marketActions.GET_LIST_OF_SERVICE_PROVIDER_WITH_THEIR_ID
            });
        };
    } catch (error) {
        console.error('Error fetching geocode data:', error.message);
    }
}

const getCurrentLocation = function* (data) {
    const { payload } = data;

    try {
        // if (navigator.geolocation) {
        // const location = yield call(getCurrentPosition);
        const { latitude, longitude } = payload;
        const result = yield call(() =>
            fetch(
                `${GOOGLE_API}/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${GOOGLE_MAPS_API_KEY}`
            ).then(response => response.json())
        );
        yield put({
            type: commonActions.SET_CURRENT_LOCATION, payload: {
                Address: result?.results[0]?.formatted_address,
                Coordinates: { latitude, longitude },
                type: 'Current'
            }
        });
        const { marketPlaceSelectedLocation } = yield select((state) => state.MarketPlaceReducer);
        yield put({
            type: marketActions.MARKETPLACE_SERVICES_SELECTED_LOCATION,
            payload: {
                ...marketPlaceSelectedLocation,
                Address: result?.results[0]?.formatted_address,
                Coordinates: { latitude: latitude, longitude: longitude },
                type: 'Current'
            }
        });
        yield put({
            type: marketActions.GET_LIST_OF_SERVICE_PROVIDER_WITH_THEIR_ID
        });
    } catch (err) {
        console.log(err);
    };
};

// function getCurrentPosition() {
//     return new Promise((resolve, reject) => {
//         navigator.geolocation.getCurrentPosition(resolve, reject);
//     });
// }

export default commonSagas;