import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { PiPlayFill } from "react-icons/pi";
import { useSelector } from "react-redux";
import { MdOutlineArrowForwardIos, MdOutlineArrowBackIosNew } from "react-icons/md";
import Slider from 'react-slick';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import NoImage from '../../assets/images/noImage.jpg';
import { LogEvent } from "../../utils/firebase";
import { GrClose } from "react-icons/gr";
import { IconButton } from "@material-ui/core";
import Modal from "react-modal";


const BlogPage = () => {

  const { t } = useTranslation();

  const navigate = useNavigate();

  const { homeContent } = useSelector(state => state.AuthReducer);
  const { currentLanguage } = useSelector((state) => state.commonReducer);
  let [testimonials, setTestimonials] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);


  useEffect(() => {
    console.log(homeContent, 'home');
    console.log(testimonials, 'testi')
    if (homeContent?.length > 0) {
      setTestimonials(
        homeContent
          ?.filter((val) => val?.type === "testimonial")
          ?.map((val) => {
            let finalLanguage = val?.language?.find(
              (lang) => lang?.language_code === currentLanguage
            );
            return { ...val, finalLanguage: finalLanguage };
          })
      );
    }
  }, [homeContent, currentLanguage]);

  const openModal = (video) => {
    LogEvent('Home_Testimonials_PlayVideo')
    setSelectedVideo(video);
    setModalIsOpen(true);
    document.body.style.overflow = "hidden";
  };

  const handleViewMore = () => {
    LogEvent('Home_Blog_ViewAll')
    navigate("/blog");
  };

  const closeModal = () => {
    setSelectedVideo(null);
    setModalIsOpen(false);
    document.body.style.overflow = "auto";
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500, 
    slidesToShow: 2.5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,      
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  
  

  return (
    <div className="container-fluid farm-equipments" style={{ backgroundColor: "#ffff" }}>
      <div className="container">
        <div className="home_page_heading_div_cls slider-arrow-custom-style-for-related-products d-flex justify-content-between">
          {t("Testimonials")}
          <div
            style={{
              display: "inline-block",
              color: "#000",
              position: "relative",
              borderRadius: "5px",
              padding: "10px 20px",
              cursor: "pointer",
              fontSize: "18px",
              transition: "color 0.3s ease",
              color: isHovered ? "rgb(5 5 5)" : "",
            }}
            onClick={handleViewMore}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
           {t('View More')}
            <svg
              style={{
                verticalAlign: "middle",
                fill: isHovered ? "rgb(35, 44, 99)" : "rgb(35, 44, 99)",
                height: "22px",
                transition: "fill 0.3s ease",
              }}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path d="M10 17l5-5-5-5v10z" />
            </svg>
          </div>
        </div>
        <div className="mt-3 " >
          {testimonials?.length > 0 ?
            <Slider {...settings}>
              {testimonials?.slice(0, 4).map((blog) => (
                <div className="testimonial-card container" key={blog.id}>
                  <div className="row">
                    <div className="col-6 image-container">
                      <img src={blog?.img_url} alt="" className="img-fluid" />
                    </div>
                    <div className="col-6 text-container">

                      <p className="testimonial-description">{formatDescription(blog)}</p>

                      <div style={{ alignItems: 'center', lineHeight: '1.2' }}> {/* Adjust lineHeight as needed */}
                        <div style={{ marginRight: '10px' }}>
                          <h2 style={{ fontSize: '16px', color: '#dc7f92', margin: '0' }}>
                            {blog?.finalLanguage?.title ?? blog?.title}
                            {/* {blog?.language.find(lang => lang.language_code === currentLanguage)?.blog.title} */}
                            {/* {blog.title} */}
                            {/* {doctor.language.find(lang => lang.language_code === currentLanguage)?.contents[0]?.designation} */}
                          </h2>
                          <p  >
                            {blog?.finalLanguage?.contents[0]?.place || blog?.language[0]?.contents[0]?.place}
                            {/* {blog?.language.find(lang => lang.language_code === currentLanguage)?.contents[0]?.blog.place} */}
                          </p>
                        </div>

                        <button
                          className="watch-video-btn"
                          onClick={() =>
                            openModal({
                              title: blog?.finalLanguage?.title ?? blog?.title,
                              City: blog?.finalLanguage?.contents[0]?.place ?? blog?.language[0]?.contents[0]?.place,
                              youtube_video_id: blog?.youtube_video_id,
                            })
                          }
                        >
                         {t('Watch Video')} 
                        </button>
                      </div>



                    </div>
                  </div>
                </div>
              ))}

            </Slider> :
            <div className="h5 my-3 text-center">{t('No Data Found')}</div>
          }
        </div>
        <Modal
          ariaHideApp={false}
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={{
            content: {
              width: "70%",
              height: "85%",
              top: "44%",
              left: "50%",
              right: "50%",
              bottom: "0%",
              marginRight: "-40%",
              transform: "translate(-50%, -50%)",
            },
          }}
        >
          {selectedVideo && (
            <div>
              <IconButton
                style={{ position: "absolute", top: "1px", right: "1px" }}
                onClick={closeModal}
              >
                <GrClose />
              </IconButton>
              <h5>
                {selectedVideo.title} ,
                <span className="text-black">{selectedVideo.City}</span>
              </h5>
              <iframe
                title={selectedVideo.title}
                width="1000"
                height="515"
                style={{
                  border: "none",
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px ",
                }}
                src={`https://www.youtube.com/embed/${selectedVideo.youtube_video_id}?enablejsapi=1`}
                frameBorder="0"
                allowFullScreen
              />
            </div>
          )}
        </Modal>
      </div>
    </div>

  );
};
const formatDescription = (testimonial) => {
  const words = testimonial?.finalLanguage?.contents[0]?.descirption
    ? testimonial.finalLanguage.contents[0].descirption.split(' ')
    : testimonial?.language[0]?.contents[0]?.descirption
      ? testimonial?.language[0]?.contents[0]?.descirption.split(' ')
      : [];

  const maxWords = 30;

  const maxLines = 30;
  const lines = [];

  for (let i = 0, wordCount = 0, lineCount = 0; i < words.length; i++) {
    if (wordCount + words[i].split('\n').length > maxWords || lineCount >= maxLines) {
      break;
    }

    lines.push(words[i]);
    wordCount += words[i].split('\n').length;
    lineCount += 1;
  }

  return `"${lines.join(' ')}"`;
};

export default BlogPage;
