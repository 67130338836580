import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import TreatmentDocPage from './doctortreatment';
import TreatmentCard from './treatmenttopcard';
import CommonDiseasesPage from './CommonDiseases';
import { PiChatText } from 'react-icons/pi';
import homeActions from '../../Redux/Home/action';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { LogEvent } from '../../utils/firebase';
import { DecryptFunction } from '../../utils/cryptoFunction';
import { useNavigate, useSearchParams } from "react-router-dom";


const FaqAccordion = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [faqItems, setFaqItems] = useState([
    {   
      question: {
        en: 'After calving how long I have to wait for placenta to come out completely?',
        tm: 'கன்று ஈன்ற பிறகு, நஞ்சுக்கொடி முழுமையாக வெளிவர எவ்வளவு நேரம் காத்திருக்க வேண்டும்?',
        mr: 'रसव झाल्यानंतर प्लेसेंटा पूर्णपणे बाहेर येण्यासाठी मला किती वेळ प्रतीक्षा करावी लागेल?'
      },  
      answer: {
        en: "The placenta will come out within 8 hours in a healthy cow. If the placenta doesn't come out immediately, seek assistance. Call YourFarm Veterinary doctor",
        tm: 'ஆரோக்கியமான பசு 8 மணி நேரத்திற்குள் தானாகவே நஞ்சுக்கொடியை வெளியேற்றும். அப்படி  வெளியே வரவில்லை என்றால், உடனடியாக கால்நடை மருத்துவரை அழைக்கவும்',
        mr: 'निरोगी गाईमध्ये 8 तासांत प्लेसेंटा बाहेर येतो. जर प्लेसेंटा बाहेर आला नाही तर त्वरित आपल्या फार्म पशुवैद्यकीय डॉक्टरांना कॉल करा'
      },
      isOpen: false,
    },
    {
      question: {
        en: 'What are the measures to reduce udder and teat-related problems?',
        tm: 'மடி மற்றும் மடிக்காம்பு தொடர்பான பிரச்சனையை குறைப்பதற்கான நடவடிக்கைகள் என்ன?',
        mr: 'कासेशी संबंधित समस्या कमी करण्यासाठी काय उपाय आहेत?'
      },
      answer: {
        en: 'Mastitis or udder swelling or change in the color of the milk are the major problems in dairy cows. To avoid these problems, it is advisable to feed the cow in a standing position after milking. For further assistance, call the vet doctor.',
        tm: 'மடிநோய், மடி வீக்கம் மற்றும் பாலின் நிறத்தில் மாற்றம் ஆகியவை கறவை மாடுகளின் முக்கிய பிரச்சனைகளாகும். இப்பிரச்னைகளை தவிர்க்க, பால் கறந்த பின், நிற்கும் நிலையில் பசுவிற்கு உணவளிக்க வேண்டும். கால்நடை மருத்துவரை அழைக்க',
        mr: 'स्तनदाह किंवा कासेची सूज किंवा दुधाचा रंग बदलणे या दुभत्या गाईंच्या प्रमुख समस्या आहेत. ही समस्या टाळण्यासाठी, दूध काढल्यानंतर गायीला उभ्या स्थितीत खायला द्यावे लागते. पशुवैद्यकीय डॉक्टरला बोलवा.'
      },
      isOpen: false,
    },
    {
      question: {
        en: 'Why is my cow not taking feed and water?',
        tm: 'என்னுடைய மாடு  ஏன் தீவனம் மற்றும் தண்ணீரை எடுக்கவில்லை?',
        mr: 'माझी गाय चारा आणि पाणी का घेत नाही?'
      },
      answer: {
        en: 'There are chances of your animal suffering from fever or indigestion due to excess feeding or immediate change in the feed or FMD or LSD etc., Connect with YourFarm Veterinary doctor to know more about the issue.',
        tm: 'உங்களுடைய மாடு காய்ச்சல், கோமாரி நோய் மற்றும் பெரியம்மை நோயால் பாதிக்கப்பட்டிருந்தால் அதனுடைய பசி எடுக்கும் தன்மை குறைந்திருக்கலாம். அதிகப்படியான தீவனம் உட்கொண்டு செரிமானப் பிரச்சனை ஏற்பட்டாலும் இது போன்று காணப்படும். மேலும் தகவல்களுக்கு யுவர்பார்ம் கால்நடை மருத்துவரை தொடர்பு கொள்ளவும்.',
        mr: 'तुमच्या जनावराला जास्त आहार दिल्याने ताप किंवा अपचनाचा त्रास होत असेल किंवा फीड किंवा FMD किंवा LSD इ. मध्ये तात्काळ बदल झाला असेल, अधिक समजून घेण्यासाठी YourFarm पशुवैद्यकीय डॉक्टरांशी संपर्क साधा.पशुवैद्यकीय डॉक्टरकडून अधिक जाणून घ्या.'
      },
      isOpen: false,
    },
    {
      question: {
        en: 'Will vaccines prevent whitish diarrhea or death in poultry?',
        tm: 'தடுப்பூசி போடுவதால்  வெள்ளைக்கழிச்சல் அல்லது கோழியில் இறப்பை  தடுக்குமா?',
        mr: '"लस पांढऱ्या रंगाचा जुलाब किंवा पोल्ट्रीमधील मृत्यू रोखू शकते का? पशुवैद्यकीय डॉक्टरकडून अधिक जाणून घ्या"'
      },
      answer: {
        en: 'Yes. Vaccination for Whitish diarrhea (Ranikhet disease) is mandatory. Connect with YourFarm Veterinary doctor to get a brief explanation of vaccines.',
        tm: 'ஆம். வெள்ளைக்கழிச்சல் நோய்க்கு (ராணிகேட் நோய்) தடுப்பூசி கட்டாயம் போட வேண்டும். மேலும் விவரங்களுக்கு  யுவர்பார்ம்  கால்நடை மருத்துவரை தொடர்பு கொள்ளவும்.',
        mr: 'होय. व्हाइटिश डायरिया (रानीखेत रोग) साठी लसीकरण अनिवार्य आहे. अधिक समजून घेण्यासाठी तुमच्या फार्म पशुवैद्यकीय डॉक्टरांशी संपर्क साधा पशुवैद्यकीय डॉक्टरकडून अधिक जाणून घ्या'
      },
      isOpen: false,
    },
    {
      question: {
        en: 'My goat/ sheep is affected with PPR or Bluetongue disease. What is the best feeding practice?',
        tm: 'எனது ஆடு/செம்மறி நீலநாக்கு நோயால் (PPR) பாதிக்கப்பட்டுள்ளது. சிறந்த உணவு நடைமுறை என்ன?',
        mr: 'माझी शेळी/मेंढी पीपीआर किंवा ब्लूटँग रोगाने बाधित आहे. सर्वोत्तम आहार सराव काय आहे'
      },
      answer: {
        en: 'Porridge of rice, rye, and millet should be given in liquid form. Call the vet doctor to know more about the feeding practices.',
        tm: 'அரிசி, கம்பு, தினை ஆகியவற்றின் கஞ்சியை திரவ வடிவில் கொடுக்க வேண்டும். கால்நடை மருத்துவரை அழைக்க',
        mr: 'तांदूळ, राई आणि बाजरी यांचे दलिया द्रव स्वरूपात द्यावे.पशुवैद्यकीय डॉक्टरकडून अधिक जाणून घ्या'
      },
      isOpen: false,
    },
  ]);

  useEffect(() => {
    dispatch({ type: homeActions.GET_HOME_CONTENTS });
    //eslint-disable-next-line
  }, []);

  const toggleAccordion = (index) => {
    setFaqItems((prevItems) =>
      prevItems.map((item, i) =>
        i === index ? { ...item, isOpen: !item.isOpen } : item
      )
    );
  };

  return (
    <>
      <div className="treatment-page-container mt-3">
        <div className="market_place_title_main_col container">
          <div className="page_heading_div_cls">
            {t("Disease Management")}
          </div>
        </div>
        <TreatmentCard />
        {/* <div className="custom-card-consultationandtreatment container">
          <div className="customtreatment-heading" style={{ padding: "0 10px;" }}>
            <strong>{t("How it Works")}?</strong>
          </div>
          <div className="d-flex justify-content-around circular-tab-container">
            <div>
              <div className='message-circle' style={{ background: 'rgba(209, 242, 204, 1)' }}>
                <PiChatText size="30" style={{ color: 'black' }} />
              </div>
              <div className="circle-label " style={{ color: 'black' }}>{t("24/7 support")}</div>
            </div>
            <div className="connector"></div>
            <div >
              <div className="message-circle " style={{ background: 'rgba(255, 209, 194, 1)' }}>
                <PiChatText size="30" style={{ color: 'black' }} />
              </div>
              <div className="circle-label" style={{ color: 'black' }}>{t("Consult with Vet")}</div>
            </div>
            <div className="connector"></div>
            <div>
              <div className="message-circle" style={{ background: 'rgba(254, 233, 161, 1)' }}>
                <PiChatText size="30" style={{ color: 'black' }} />
              </div>
              <div className="circle-label" style={{ color: 'black' }}>{t("Buy herbals for diseased animal")}</div>
            </div>
          </div>
        </div> */}
        <CommonDiseasesPage />
        <div className="custom-card-treatment mt-4 container">
          <div className="custom-content">
            <h2 className="custom-heading">{t("Check our range of services")}</h2>
            <div style={{display: 'flex', justifyContent: 'center'}}>
            <p className="custom-paragraph">
            {t('The health of your animals is our top priority. Our veterinary doctors are available 24/7 to support you. We’re dedicated to helping farmers build successful and sustainable dairy operations. Reach out anytime—we’re here to help!')} </p>
            </div>
            <div className="custom-buttons">
              {/* <button className="custom-button call-button">{t("Call Now")}</button> */}
              <button className="custom-button call-button" onClick={() => {
                const phoneNumber = DecryptFunction(sessionStorage.getItem('pilot_no'));
                LogEvent('Floating_Call_Button_Clicked');
                window.location.href = `tel:${phoneNumber}`;
              }}>{t("Call Now")}</button>

              <button
                className="custom-button explore-button"
                onClick={() => navigate('/')}
              >
                {t("Explore")}
              </button>              {/* <button className="custom-button explore-button">{t("Explore")}</button> */}
            </div>
          </div>
        </div>
        <div className="consult-frequent-div mt-5" style={{ backgroundColor: "#F6F7FF" }}>
          <Container>
            <div className='frequent-heading-cls'>{t("Frequently Asked Questions")}</div>
            {faqItems.map((item, index) => (
              <div key={index} className='fre-ques-map-div'>
                <div
                  className={`faq-question ${item.isOpen ? 'open' : ''} individual-question`}
                  onClick={() => toggleAccordion(index)}
                >
                  {/* <span className='frequent-questions-span'>{item.question}</span> */}
                  <span className='frequent-questions-span'>{item.question[i18n.language]}</span>
                  {item.isOpen ? (
                    <IoIosArrowUp />
                  ) : (
                    <IoIosArrowDown />
                  )}
                </div>
                {item.isOpen && (
                  <div>
                    {/* <div className="faq-answer">{item.answer}</div> */}
                    <div className="faq-answer">{item.answer[i18n.language]}</div>
                  </div>
                )}
              </div>
            ))}
          </Container>
        </div>
        <TreatmentDocPage />
      </div>
    </>
  );
};

export default FaqAccordion;
