import React from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import market_Action from "../../Redux/marketPlace/action";
import Slider from "react-slider";
import { LogEvent } from "../../utils/firebase";
import farmActions from "../../Redux/sellerProfile/action";

export default function SidebarFilter() {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { cattleParentCatgory, marketPlaceSelectedLocation, cattleListingsSelectedFilters } = useSelector(state => state.MarketPlaceReducer);

    const { currentLanguage } = useSelector(state => state.commonReducer);

    const handleCategoryClick = (id,category) => {
        LogEvent('LiveStock_Categories_Clicked')
        let marketplace = { ...marketPlaceSelectedLocation };
        marketplace['Category'] = id;
        // marketplace['Category'] = category;
        dispatch({ type: market_Action.MARKETPLACE_SERVICES_SELECTED_LOCATION, payload: marketplace });
        dispatch({ type: market_Action.GET_CATEGORY_WISE_LISTINGS_DIARY_CATTLE });
        dispatch({ type: farmActions.GET_CATTLE_DISEASE, payload: id });
      
    };

    const handleAgeSliderChange = (data) => {
        LogEvent('LiveStock_AgeFilter_Clicked')
        let marketplace = { ...cattleListingsSelectedFilters };
        marketplace['Age'] = data;
        dispatch({ type: market_Action.CATTLE_LISTINGS_SELECTED_FILTERS, payload: marketplace });
        dispatch({ type: market_Action.CATTLE_LISTINGS_FILTER });
        
    };

    const handlePriceSliderChange = (data) => {
        LogEvent('LiveStock_PriceFilter_Clicked')
        let marketplace = { ...cattleListingsSelectedFilters };
        marketplace['PriceFilter'] = { Min: data[0], Max: data[1] };
        dispatch({ type: market_Action.CATTLE_LISTINGS_SELECTED_FILTERS, payload: marketplace });
        dispatch({ type: market_Action.CATTLE_LISTINGS_FILTER });
    };

    const handleClear = () => {
        dispatch({ type: market_Action.CATTLE_LISTINGS_FILTER, payload: { type: 'clear' } });
    };

 
   
    return (
        <div>
            <div className="market-animals-cate-filter-main-div">
                <div className="market-animals-cate-filter-header">{t("Categories")}</div>
                {cattleParentCatgory?.map((category, i) =>
                    <div
                        className={`market-animals-cate-filter-category cursor-pointer ${marketPlaceSelectedLocation?.Category === category?.id && "market-place-cate-filter-active-div"}`}
                        key={i}
                        onClick={() => handleCategoryClick(category?.id, category)}
                    >
                        <div className={`market-animals-cate-filter-div`} key={i}>
                            <img src={category?.image_url} alt="" className="market-animals-cate-filter-img" />
                            <div className="market-animals-cate-filter-title">{category?.language?.find(lang => lang?.language_code === currentLanguage)?.title || category?.title}</div>
                        </div>
                    </div>
                )}
            </div>
            <div className="market-animals-cate-filter-main-div mt-4">
                <div className="d-flex justify-content-between market-animals-cate-filter-header">
                    <div>{t("Filter By")}</div>
                    <div className="marketplace-filter-clear-div cursor-pointer" onClick={handleClear}>{t("Clear all")}</div>
                </div>
                <div className="marketplace_filters_main_div">
                    <h5 className="products_list_page_filter_by_text d-flex my-4">{t("Age")} <div className="products_list_page_filter_by_text_sub_div">({t('In years')})</div></h5>
                    <Slider
                        className="slider mt-3"
                        value={cattleListingsSelectedFilters?.Age}
                        onChange={handleAgeSliderChange}
                        min={1}
                        max={15}
                    />
                    {cattleListingsSelectedFilters?.Age?.length > 0 &&
                        <div className="d-flex justify-content-between mt-3">
                            <span className="range-minandmax">{cattleListingsSelectedFilters?.Age[0]} {cattleListingsSelectedFilters?.Age[0] === 1 ? "yr" : "yrs"}</span>
                            <span className="range-minandmax">{cattleListingsSelectedFilters?.Age[1]} {cattleListingsSelectedFilters?.Age[0] === 1 ? "yr" : "yrs"}</span>
                        </div>
                    }
                    <h5 className="products_list_page_filter_by_text d-flex my-4">{t("Price")} <div className="products_list_page_filter_by_text_sub_div">({t('In INR')})</div></h5>
                    <Slider
                        className="slider mt-3"
                        value={[cattleListingsSelectedFilters?.PriceFilter?.Min, cattleListingsSelectedFilters?.PriceFilter?.Max]}
                        onChange={handlePriceSliderChange}
                        min={cattleListingsSelectedFilters?.Min || 10000}
                        max={cattleListingsSelectedFilters?.Max || 50000}
                    />
                    <div className="d-flex justify-content-between mt-3">
                        <span className="range-minandmax">₹{cattleListingsSelectedFilters?.PriceFilter?.Min}</span>
                        <span className="range-minandmax">₹{cattleListingsSelectedFilters?.PriceFilter?.Max}</span>
                    </div>
                </div>
            </div>
            <div className="marketplace_cattle_category_for_small_display">
                <div className="marketplace_cattle_category_for_small d-flex mb-4">
                    {cattleParentCatgory?.map((category, i) =>
                        <div
                            className={`marketplace_cattle_cate_filter_cate_ss ${marketPlaceSelectedLocation?.Category === category?.id && "market-place-cate-filter-active-div-for-ss"}`}
                            key={i}
                            onClick={() => handleCategoryClick(category?.id, category)}
                        >
                            <img src={category?.image_url} alt="" className="market-animals-cate-filter-img-ss" />
                            <div className="market-animals-cate-filter-title-ss">{category?.language?.find(lang => lang?.language_code === currentLanguage)?.title || category?.title}</div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}