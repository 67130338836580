import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Button, Modal,Col } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import commonActions from "../../Redux/common/actions";
import userActions from "../../Redux/user/actions";
import { DecryptFunction } from "../../utils/cryptoFunction";
import EditAddressModal from "./editAddressModal";
import { useTranslation } from 'react-i18next';
import { LogEvent } from "../../utils/firebase";

export default function AddressBook() {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { userAddress } = useSelector(state => state.userReducer);

    const [defaultAddress, setDefaultAddress] = useState();
    const [showEditModal, setShowEditModal] = useState({ isOpen: false, value: null });
    const [showRemoveModal, setShowRemoveModal] = useState({ isOpen: false, id: null, value: null });

    useEffect(() => {
        console.log(userAddress, 'address');
        
        if (userAddress && userAddress?.length > 0) {
            setDefaultAddress(userAddress?.find((val => val?.default_address === 1)));
        };
    }, [userAddress]);

    const handleAddAddress = () => {
        LogEvent('Profile_AddAddress_Clicked')
        dispatch({ type: commonActions.SET_SHOW_USER_ADDRESS_MODAL, payload: true });
    };

    const handleUpdateDefault = (value) => {
        LogEvent('Profile_Address_SetDefault_Clicked')
        const id = DecryptFunction(localStorage.getItem('auth_user'));
        if (id) {
            let data = {
                "address_line1": value?.address_line1,
                "address_line2": value?.address_line2,
                "address_line3": value?.address_line3,
                "city": value?.city,
                "state": value?.state,
                "country": value?.country,
                "other_address_details": value?.other_address_details,
                "user_id": id,
                "pincode": value?.pincode,
                "address_type": value?.address_type,
                "default_address": 1
            };
            dispatch({ type: userActions.UPDATE_USER_ADDRESS, payload: { id: value?.id, data: data } });
        }
    };

    const handleUpdateAddress = (value) => {
        const id = DecryptFunction(localStorage.getItem('auth_user'));
        if (id) {
            let data = {
                "address_line1": value?.address_line1,
                "address_line2": value?.address_line2,
                "address_line3": value?.address_line3,
                "city": value?.city,
                "state": value?.state,
                "country": value?.country,
                "other_address_details": value?.other_address_details,
                "user_id": id,
                "pincode": value?.pincode,
                "address_type": value?.address_type,
                "default_address": value?.default_address
            };
            dispatch({ type: userActions.UPDATE_USER_ADDRESS, payload: { id: value?.id, data: data } });
        };
        setShowEditModal(false);
    };

    const handleRemove = () => {
        const id = DecryptFunction(localStorage.getItem('auth_user'));
        if (showRemoveModal?.id && id) {
            dispatch({ type: userActions.REMOVE_USER_ADDRESS, payload: { id: showRemoveModal?.id, user_id: id, value: showRemoveModal?.value } });
        };
        setShowRemoveModal({ isOpen: false, id: null });
    };

    return (
        <>
           
                <div className="user-profile-card-title d-flex justify-content-between">
                    <div >
                       
                    </div>
                    <div className="Add-button-container">
            <Button size="sm" className="user-profile-address-book-add-btn" onClick={handleAddAddress}>
                <FaPlus /> {t("Add")}
            </Button>
        </div>
                    {/* <Button size="sm" className="user-profile-address-book-add-btn" onClick={handleAddAddress}><FaPlus /> {t("Add")}</Button> */}
                </div>
                {userAddress?.length > 0 ?
                    <>
                        {userAddress?.map((val, i) =>
                        <div>  <div key={i} className={`user-profile-address-div ${(defaultAddress && defaultAddress?.id === val?.id) && 'user-profile-default-address'}`}>
                        <div className="d-flex justify-content-between">
                            <div className="d-flex">
                                <input
                                    type="radio"
                                    className="me-2 mb-1"
                                    checked={(defaultAddress?.id === val?.id || val?.default_address === 1) ? true : false}
                                />
                                <div className="fw-600">{val?.address_type}</div>
                            </div>
                        </div>
                        <div className="address-book-address mb-2">
                            <div>{`${val?.address_line1}, ${val?.address_line2}, ${val?.address_line3},`}</div>
                            <div>{`${val?.city} - ${val?.pincode}.`}</div>
                            <div>{`${val?.state}, ${val?.country}`}</div>
                        </div>
                        <div className="d-flex justify-content-end">
                            <Button className="me-2 user-profile-address-btn" size="sm" onClick={() => {
                                LogEvent('Profile_EditAddress_Clicked')
                                setShowEditModal({ isOpen: true, value: val })
                                }}>{t("Edit")}</Button>
                            <Button className="me-2 user-profile-address-btn" size="sm" onClick={() => {
                                LogEvent('Profile_RemoveAddress_Clicked')
                                setShowRemoveModal({ isOpen: true, id: val?.id, value: val })
                                }}>{t("Remove")}</Button>
                            {(!defaultAddress || (defaultAddress && defaultAddress?.id !== val?.id)) &&
                                <Button className="user-profile-address-btn" size="sm" onClick={() => handleUpdateDefault(val)}>{t("Set as Default")}</Button>
                            }
                        </div>
                    </div></div>
                          
                        )}
                    </> :
                    <div className="nocoupons">
                        {t("No Data Found")}
                    </div>
                }

                <EditAddressModal
                    showModal={showEditModal?.isOpen}
                    closeModal={() => setShowEditModal({ isOpen: false, value: null })}
                    address={showEditModal?.value}
                    handleUpdateAddress={handleUpdateAddress}
                />
                <Modal
                    show={showRemoveModal?.isOpen}
                    onHide={() => setShowRemoveModal({ isOpen: false, id: null })}
                    centered
                    size="md"
                >
                    <div className="modal_close_icons"
                        onClick={() => setShowRemoveModal({ isOpen: false, id: null })}
                    >
                        <svg
                            stroke="currentColor"
                            fill="currentColor"
                            stroke-width="0"
                            viewBox="0 0 24 24"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path fill="none" d="M0 0h24v24H0z"></path>
                            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                        </svg>
                    </div>
                    <Container className="my-5 text-center">
                        <h5>{t("Do you want to delete the address")}?</h5>
                        <div className="d-flex justify-content-center mt-4">
                            <Button className="me-2" onClick={() => setShowRemoveModal({ isOpen: false, id: null })}>{t("No")}</Button>
                            <Button onClick={handleRemove}>{t("Yes")}</Button>
                        </div>
                    </Container>
                </Modal>
          
        </>
    )
};