import cartActions from "./actions";
import commonActions from "../common/actions";
import { put, call, all, takeEvery, delay, select } from "redux-saga/effects";
import { API_URL } from "../../utils/constant";
import axios from "axios";
import { DecryptFunction } from "../../utils/cryptoFunction";

const cartSagas = function* () {
    yield all([
        yield takeEvery(cartActions.GET_CART_PRODUCT_LIST, getCartProductsList),
        yield takeEvery(cartActions.ADD_PRODUCT_TO_CART, addProductToCart),
        yield takeEvery(cartActions.BUY_NOW_ACTION, buyNowAction),
        yield takeEvery(cartActions.REMOVE_PRODUCT_FROM_CART, removeProductFromCart),
        yield takeEvery(cartActions.EMPTY_CART, emptyFromCart),
    ])
};

const getCartProductsList = function* (data) {
    const { payload } = data;
    const { cartProducts } = yield select(state => state.cartReducer);
    if ((cartProducts?.length === 0 && !payload?.type) || payload?.type) {
        yield put({ type: commonActions.PAGE_LOADER, payload: true });
        try {
            yield put({ type: cartActions.SET_CART_PRODUCT_LIST, payload: [] });
            const decryptedId = DecryptFunction(payload?.id);
            if (decryptedId) {
                const result = yield call(() =>
                    axios.get(`${API_URL}/v1/user/mycart/${decryptedId}`)
                );
                if (result?.data?.code === 200) {
                    yield put({ type: cartActions.SET_CART_PRODUCT_LIST, payload: result?.data?.data[0] });
                    // const { checkoutValues } = yield select((state) => state.checkoutReducer);
                } else {
                    yield put({ type: cartActions.SET_CART_PRODUCT_LIST, payload: [] });
                }
            };
        } catch (err) {
            yield put({ type: cartActions.SET_CART_PRODUCT_LIST, payload: [] });
            if (err?.response?.data?.code === 401) {
                localStorage.removeItem('token');
                localStorage.removeItem('auth_user_no');
                localStorage.removeItem('auth_user');
                localStorage.removeItem('auth_user_provider_id');
                yield put({ type: commonActions.GET_COMMON_TOKEN });
            }
        };
    }
    yield put({ type: commonActions.PAGE_LOADER, payload: false });
};

///me
const addProductToCart = function* (data) {
    const { payloadData, cattleType } = data;

    try {
        const userid = DecryptFunction(localStorage.getItem('auth_user'));
        const { userAddress } = yield select(state => state.userReducer); //ask
        const { CurrentAddingCartProduct, cartProducts } = yield select(state => state.cartReducer); //ask

        if (userid) {
            let payload = { ...CurrentAddingCartProduct, user_id: userid, location_id: userAddress[0]?.id };

            const result = yield call(() =>
                axios.post(`${API_URL}/v1/user/addcart`, payload)
            );

            yield put({ type: cartActions.GET_CART_PRODUCT_LIST, payload: { id: localStorage.getItem('auth_user'), type: 'updated' } });

            // Store the cattleType in local storage
            localStorage.setItem('cattle_type', cattleType);

            if (!payloadData?.type) {
                if (cartProducts?.id) {
                    let orderItems = cartProducts?.order_items;
                    let onlyItemList = ["Market", "feed", "Feed"];
                    let onlyItem = onlyItemList.find(e => e === cattleType);
                    let deleteItem = [];

                    if (onlyItem) {
                        orderItems.forEach(el => {
                            if (el?.cattle_type !== onlyItem) {
                                deleteItem.push(el);
                            }
                        });
                    } else {
                        orderItems.forEach(el => {
                            if (onlyItemList.find(e => e === el.cattle_type)) {
                                deleteItem.push(el);
                            }
                        });
                        yield put({ type: cartActions.GET_CART_PRODUCT_LIST });
                    }

                    for (const el of deleteItem) {
                     yield call(() =>
                            axios.delete(`${API_URL}/v1/user/removecartitem/${el?.id}/${el?.order_id}/${userid}`)
                        );
                    
                        let result = yield call(() =>
                            axios.get(`${API_URL}/v1/user/mycart/${userid}`)
                        );
                        console.log("result", result.data)
                        yield put({ type: cartActions.SET_CART_PRODUCT_LIST, payload: result?.data?.data[0] });
                    }

                    if (deleteItem.length > 0) {
                        yield put({
                            type: commonActions.SET_ALERT,
                            payload: {
                                show: true,
                                status: "success",
                                message: "Products other than Market or Feed are removed",
                            },
                        });
                    } else {
                        yield put({
                            type: commonActions.SET_ALERT,
                            payload: {
                                show: true,
                                status: result?.data?.code === 200 ? "success" : "failed",
                                message: result?.data?.message || "Unknown error occurred",
                            },
                        });
                    }
                }

            } else {
                yield put({
                    type: commonActions.SET_ALERT,
                    payload: {
                        show: true,
                        status: "success",
                        message: "You have updated the quantity of the product",
                    },
                });
            }

            yield delay(2000);
            // yield put({
            //     type: cartActions.GET_CART_PRODUCT_LIST,
            //      payload: { id: userid }
            // })
            yield put({
                type: cartActions.CURRENT_ADDING_CART_PRODUCT,
                payload: null
            });
         
            yield put({
                type: commonActions.SET_ALERT,
                payload: { show: false, message: null, status: null },
            });
        }
    } catch (err) {
        console.log(err);
    }
};

// const addProductToCart = function* (data) {

//     const { payloadData,cattleType } = data;

//     try {
//         const userid = DecryptFunction(localStorage.getItem('auth_user'));
//         const { userAddress } = yield select(state => state.userReducer);
//         const { CurrentAddingCartProduct, cartProducts } = yield select(state => state.cartReducer);
//             if (userid) {
//                 let payload = { ...CurrentAddingCartProduct, user_id: userid, location_id: userAddress[0]?.id };

//                 const result = yield call(() =>
//                     axios.post(`${API_URL}/v1/user/addcart`, payload)
//                 );
//                 yield put({ type: cartActions.GET_CART_PRODUCT_LIST, payload: { id: localStorage.getItem('auth_user'), type: 'updated' } });
//                 // yield put({ type: cartActions.CURRENT_ADDING_CART_PRODUCT, payload: null });

//                 if (!payloadData?.type) {
//                     if (cartProducts?.id) {
//                         let orderItems = cartProducts?.order_items;
//                         let onlyItemList = ["Market", "feed","Feed"];
//                         let onlyItem = onlyItemList.find(e => e === cattleType);
//                         let deleteItem = [];

//                         if (onlyItem) {
//                             orderItems.forEach(el => {
//                                 if (el?.cattle_type !== onlyItem) {
//                                     deleteItem.push(el);
//                                 }
//                             });
//                         } else {
//                             orderItems.forEach(el => {
//                                 if (onlyItemList.find(e => e === el.cattle_type)) {
//                                     deleteItem.push(el);
//                                 }
//                             });
//                         }

//                         for (const el of deleteItem) {
//                             yield call(() =>
//                                 axios.delete(`${API_URL}/v1/user/removecartitem/${el?.id}/${el?.order_id}/${userid}`)
//                             );
//                         }
//                         if (deleteItem.length > 0) {
//                             yield put({
//                                 type: commonActions.SET_ALERT,
//                                 payload: {
//                                     show: true,
//                                     status: "success",
//                                     message: "Products Other than Market(or)Feed is removed",
//                                 },
//                             });
//                         } else {
//                             yield put({
//                                 type: commonActions.SET_ALERT,
//                                 payload: {
//                                     show: true,
//                                     status: result?.data?.code === 200 ? "success" : "failed",
//                                     message: result?.data?.message || "Unknown error occurred",
//                                 },
//                             });
//                         }
//                     }

//                 } else {

//                     yield put({
//                         type: commonActions.SET_ALERT,
//                         payload: {
//                             show: true,
//                             status: "success",
//                             message: "You have updated the quantity of the product",
//                         },
//                     });
//                 }
//                 yield delay(2000);
//                 yield put({ type: cartActions.CURRENT_ADDING_CART_PRODUCT, payload: null });
//                 yield put({
//                     type: commonActions.SET_ALERT,
//                     payload: { show: false, message: null, status: null },
//                 });
//             }
//         // }
//     } catch (err) {
//         console.log(err);
//     };
// };

const buyNowAction = function* (data) {

    const { payloadData } = data;
    const { navigate } = payloadData;
    try {
        const userid = DecryptFunction(localStorage.getItem('auth_user'));
        const { userAddress } = yield select(state => state.userReducer);
        const { CurrentAddingCartProduct } = yield select(state => state.cartReducer);
        if (userid) {
            let payload = { ...CurrentAddingCartProduct, user_id: userid, location_id: userAddress[0]?.id };

            const result = yield call(() =>
                axios.post(`${API_URL}/v1/user/addcart`, payload)
            );
            yield put({ type: cartActions.GET_CART_PRODUCT_LIST, payload: { id: localStorage.getItem('auth_user'), type: 'updated' } });
            yield put({
                type: commonActions.SET_ALERT,
                payload: {
                    show: true,
                    status: result?.data?.code === 200 ? "success" : "failed",
                    message: result?.data?.message,
                },
            });
            if (payloadData?.type === 'buy') {
                navigate('/cart');
            };
            yield delay(2000);
            yield put({ type: cartActions.CURRENT_ADDING_CART_PRODUCT, payload: null });
            yield put({
                type: commonActions.SET_ALERT,
                payload: { show: false, message: null, status: null },
            });
        }
    } catch (err) {
        console.log(err);
    };
};

const removeProductFromCart = function* (data) {

    const { payload } = data;

    try {
        const user_id = DecryptFunction(payload?.user_id);
        if (user_id) {
            const result = yield call(() =>
                axios.delete(`${API_URL}/v1/user/removecartitem/${payload?.id}/${payload?.order_id}/${user_id}`)
            );
            yield put({ type: cartActions.GET_CART_PRODUCT_LIST, payload: { id: payload?.user_id, type: 'updated' } });
            yield put({
                type: commonActions.SET_ALERT,
                payload: {
                    show: true,
                    status: result?.data?.code === 200 ? "success" : "failed",
                    message: result?.data?.message,
                },
            });
            yield delay(2000);
            yield put({
                type: commonActions.SET_ALERT,
                payload: { show: false, message: null, status: null },
            });
        }
    } catch (err) {
        console.log(err);
    };
};
const emptyFromCart = function* (data) {

    const { payload } = data;
    try {
        const user_id = DecryptFunction(payload?.user_id);
        if (user_id) {
            const result = yield call(() =>
                axios.delete(`${API_URL}​/v1​/user​/orders​/emptycart/${payload?.id}/${user_id}`)
            );
            yield put({ type: cartActions.GET_CART_PRODUCT_LIST, payload: { id: payload?.user_id, type: 'updated' } });
            yield put({
                type: commonActions.SET_ALERT,
                payload: {
                    show: true,
                    status: result?.data?.code === 200 ? "success" : "failed",
                    message: result?.data?.message,
                },
            });
            yield delay(2000);
            yield put({
                type: commonActions.SET_ALERT,
                payload: { show: false, message: null, status: null },
            });
        }
    } catch (err) {
        console.log(err);
    };
};


export default cartSagas;
