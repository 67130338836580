import React from 'react';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Schreiber from "../../../assets/images/consulation/DairyPartners/Schreiber.png";
import Prabhat from "../../../assets/images/consulation/DairyPartners/Prabhat.png";
import Tirumala from "../../../assets/images/consulation/DairyPartners/tirumala.png";
import Govind from "../../../assets/images/consulation/DairyPartners/govind.png";
import Milky from "../../../assets/images/consulation/DairyPartners/Milky mist.png";
import Baani from "../../../assets/images/consulation/DairyPartners/Baani.png";
import Vilfresh from "../../../assets/images/consulation/DairyPartners/vilfresh.png";
import RGS from "../../../assets/images/consulation/DairyPartners/Rgs vet.png";



const Partnertag = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const products = [
    { id: 1, image_url: Schreiber },
    { id: 2, image_url: Prabhat },
    { id: 3, image_url: Tirumala },
    { id: 4, image_url: Govind },
    { id: 5, image_url: Milky },
    { id: 6, image_url: Baani },
    { id: 7, image_url: Vilfresh },
    { id: 8, image_url: RGS }
  ];

  return (
    <div className='container-fluid' style={{ backgroundColor: '#F6F7FF', padding: '2rem 0', marginTop:'60px' }}>
      <div className='container'>
        <div className="New_achievement_div_cls">
          {t("Our Dairy Partners")}
        </div>
        <div className='product-row'>
          {products.map((product) => (
            <div key={product.id} className='product-item'>
              <img src={product.image_url} alt={`Product ${product.id}`} className='product-image' />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Partnertag;
